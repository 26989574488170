"use client";

import { useEffect, useRef, useState } from "react";

import { classNames } from "./accordion.styles";
import { AccordionProps } from "./accordion.types";
import { Icon } from "@scalis/components/core/icon";

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  open,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(open);
  const [contentHeight, setContentHeight] = useState<number>();

  const { container, content, header, headerIcon, headerTitle } = classNames({
    isOpen,
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight);
    }
  }, [children]);

  const toggleOpenState = () => {
    setIsOpen(isOpen => !isOpen);
  };

  return (
    <section className={container({ isOpen })}>
      <div className={header({ isOpen })}>
        <h1 className={headerTitle({ isOpen })}>{title}</h1>
        <button 
          type="button"
          onClick={toggleOpenState}
          className={headerIcon({ isOpen })}
        >
          <Icon icon="fa-regular fa-chevron-right" />
        </button>
      </div>

      <div className={content({ isOpen })} style={{ maxHeight: contentHeight }}>
        <div className="w-full" ref={contentRef}>
          {children}
        </div>
      </div>
    </section>
  );
};
