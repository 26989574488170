import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { ChatMessageCardProps } from "./chat-message-card.types";
import {
  formatMessageDateFromInputFormat,
  replaceStylesInHTMLString,
} from "../../../messages.utils";

export const ChatMessageCard: React.FC<ChatMessageCardProps> = ({
  timestamp,
  message,
  user,
}) => {
  const formatDate = () => {
    const formattedDate = formatMessageDateFromInputFormat(timestamp);
    return formattedDate;
  };

  return (
    <div className="flex h-fit w-full flex-nowrap items-start gap-4 bg-neutral-00">
      <ProfilePicture src={user?.imgSrc} />

      <section className="flex flex-1 flex-col flex-nowrap items-stretch gap-1">
        <h2 className="flex flex-nowrap items-center justify-start gap-2 text-sm font-semibold text-neutral-primary">
          {user.name}

          <span className="text-xs font-normal tracking-[-0.3px] text-neutral-tertiary">
            {formatDate()}
          </span>
        </h2>

        <p
          className="text-sm tracking-[-0.6px] text-neutral-secondary"
          dangerouslySetInnerHTML={{
            __html: replaceStylesInHTMLString(message),
          }}
        />
      </section>
    </div>
  );
};
