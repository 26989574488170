import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const tabClassName = tv({
  base: cn(
    "flex-1 w-fit py-2 px-4 h-10 flex items-center justify-center gap-2",
    "leading-6 text-sm font-medium text-neutral-secondary",
    "border-b border-bg-neutral-30 bg-neutral-00",
  ),
  variants: {
    disabled: {
      true: "border-b-neutral-disabled text-neutral-disabled",
      false: "hover:bg hover:bg-surface-neutral-rest",
    },
    selected: {
      true: "border-b-2 border-bg-brand-primary-dark-00 text-neutral-primary",
    },
  },
});
