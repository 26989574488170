import { format, fromUnixTime } from "date-fns";

export const formatMessageDateFromInputFormat = (
  timestamp = Date.now(),
  isDate?: boolean,
) => {
  const SHORT_DATE_OUTPUT_FORMAT = "MMM dd";
  const FULL_DATE_OUTPUT_FORMAT = "hh:mm a";

  const fullDateParsed = fromUnixTime(timestamp ?? Date.now());

  if (isDate) {
    return format(timestamp, SHORT_DATE_OUTPUT_FORMAT);
  }

  return format(fullDateParsed, FULL_DATE_OUTPUT_FORMAT);
};

const DEFAULT_STYLES = "text-sm font-normal leading-6";
export const replaceStylesInHTMLString = (
  htmlString: string,
  className = DEFAULT_STYLES,
) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const replaceStyles = (element: Element) => {
    element.removeAttribute("class");
    element.removeAttribute("style");
    element.classList.add(...className.split(" "));

    Array.from(element.children).forEach(child => {
      replaceStyles(child);
    });

    if (element?.textContent?.trim() === "" && element.children.length === 0) {
      element.remove();
    }
  };

  doc.body.querySelectorAll("*").forEach(element => {
    replaceStyles(element);
  });

  return doc.body.innerHTML;
};
