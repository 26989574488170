"use client";

import { Divider } from "~/scalis-components/core/divider";
import { Chat } from "./chat";
import { MessagesProvider } from "./hooks";
import { SideMenu } from "./side-menu";
import { NewChatStateProvider } from "./chat/hooks/use-new-chat-state";
import { cn } from "~/utils/cn";

const MessagesComponent: React.FC = () => {
  return (
    <div
      className={cn(
        `flex max-h-[calc(100dvh_-_76px)] w-full flex-[1_1_calc(100dvh_-_76px)] flex-nowrap items-stretch overflow-hidden rounded-tl-xl first:*:min-w-[280px]`,
        "first:*:flex-1 [&:nth-child(2)]:ml-4",
      )}
    >
      <SideMenu />

      <Divider vertical />

      <NewChatStateProvider>
        <Chat />
      </NewChatStateProvider>
    </div>
  );
};

export const Messages: React.FC = () => (
  <MessagesProvider>
    <MessagesComponent />
  </MessagesProvider>
);
