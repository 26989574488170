"use client";

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
} from "react";
import { AblyProvider } from "ably/react";
import * as Ably from "ably";

import {
  ChatState,
  MessagesActionType,
  MessagesContext,
  MessagesReducer,
  MessagesState,
  MessagesUsersReducer,
  MobileStep,
} from "./use-messages.types";
import { isJobSeekerUser, useUser } from "~/hooks";

const messagesContext = createContext<MessagesContext | null>(null);

export const useMessages = (): MessagesContext => {
  const messages = useContext(messagesContext);

  if (!messages) {
    throw new Error("useMessages must be used within a MessagesProvider");
  }

  return messages;
};

export const MessagesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, profilePicture } = useUser();

  const [state, dispatch] = useReducer(messagesReducer, initialState);

  useEffect(() => {
    if (user && profilePicture) {
      const isJobSeeker = isJobSeekerUser(user);

      const id = user?.id.toString();
      const name = isJobSeeker
        ? `${user.job_seeker_basicinfo.f_name} ${user.job_seeker_basicinfo.l_name}`
        : user.full_name ?? "";
      const imgSrc = profilePicture;
      const haveUser = id && name && imgSrc;

      dispatch({
        type: MessagesActionType.setLoggedUser,
        user: { id, name, imgSrc },
      });
    }
  }, [user, profilePicture]);

  const value = useMemo<MessagesContext>(
    () => ({ ...state, dispatch }),
    [state],
  );

  const client = useMemo(() => {
    return new Ably.Realtime({ authUrl: "/api/messaging/authenticate" });
  }, []);

  return (
    <AblyProvider client={client}>
      <messagesContext.Provider value={value}>
        {children}
      </messagesContext.Provider>
    </AblyProvider>
  );
};

const initialState: MessagesState = {
  chatState: ChatState.ongoingChat,
  users: {},
  mobileStep: MobileStep.chatsPreview,
  hasMessages: false,
};

const reduceUsers: MessagesUsersReducer = (users, user) => {
  if (user) {
    return { ...users, [user.id]: user };
  }

  return users;
};

const messagesReducer: MessagesReducer = (state, action) => {
  switch (action.type) {
    case MessagesActionType.setCurrentUser:
      return {
        ...state,
        currentUser: action.currentUser,
        users: reduceUsers(state.users, action.currentUser),
      };

    case MessagesActionType.setChatState:
      return { ...state, chatState: action.chatState };

    case MessagesActionType.setLoggedUser:
      return { ...state, users: reduceUsers(state.users, action.user) };

    case MessagesActionType.setMobileStep:
      return { ...state, mobileStep: action.step };

    case MessagesActionType.hasMessages:
      return { ...state, hasMessages: action.hasMessages };

    default:
      return state;
  }
};
