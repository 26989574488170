import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ReactQueryMutationHook } from "~/hooks/hooks.types";
import {
  CreateChannelError,
  CreateChannelSchema,
  CreateChannelSuccess,
} from "~/pages/api/messaging/channels";
import { useGetChannels } from "../../../side-menu/preview-messages/hooks/use-get-channels";

const createChannel = async (data: CreateChannelSchema) => {
  const response = await axios.post<CreateChannelSuccess>(
    "/api/messaging/channels",
    data,
  );

  return response.data;
};

type UseCreateChannelMutationParams = [];
type UseCreateChannelMutationFn = typeof createChannel;

export const useStartChat: ReactQueryMutationHook<
  UseCreateChannelMutationParams,
  UseCreateChannelMutationFn,
  CreateChannelError
> = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: useStartChat.generateKey(),
    mutationFn: createChannel,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: useGetChannels.generateKey(),
      });
    },
  });
};

useStartChat.generateKey = () => ["messaging/channel/create"];
