import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const classNames = tv({
  slots: {
    container: "h-full w-full bg-neutral-00",
    header: cn(
      "grid grid-cols-[1fr_auto] items-center gap-4",
      "border-b-[1px] border-solid",
      "border-b-bg-neutral-30 px-4 py-2.5",
    ),
    headerTitle: "text-sm font-medium text-brand-primary-rest",
    headerIcon:
      "text-brand-primary-rest text-sm transition-transform duration-300",
    content: cn("overflow-hidden", `transition-[max-height] ease-linear`),
  },
  variants: {
    isOpen: {
      true: {
        headerIcon: "-rotate-90",
      },
      false: {
        content: "!max-h-0",
      },
    },
  },
});
