"use client";

import { useEffect, useMemo, useState } from "react";
import { ChannelProvider } from "ably/react";
import { Message as AblyMessage } from "ably";

import {
  UseGetMessagesData,
  Message,
  GetMessagesProviderProps,
} from "./use-get-messages.types";
import { useMessageChannel } from "./use-message-channel";

const ablyMessageToMessageData = (data: AblyMessage): Message => {
  return {
    id: data.id ?? "",
    message: data.data,
    createdAt: data.timestamp,
    userId: data.clientId ?? "",
  };
};

export const useGetMessages = (channelName?: string): UseGetMessagesData => {
  const [loading, setLoading] = useState(true);
  const [readMessages, setReadMessages] = useState<Message[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<Message[]>([]);

  const { history } = useMessageChannel(
    channelName,
    message =>
      !loading &&
      setReadMessages(prev => [...prev, ablyMessageToMessageData(message)]),
  );

  useEffect(() => {
    setReadMessages([]);
    setUnreadMessages([]);
  }, [channelName]);

  useEffect(() => {
    getHistory();
  }, [history]);

  const getHistory = async () => {
    const historyItems = await history();

    historyItems.map(item => item);

    const readMessages: Message[] = historyItems.map(message => ({
      id: message.id,
      createdAt: message.timestamp,
      message: message.data ?? "",
      userId: message.clientId ?? "",
    }));

    setReadMessages(readMessages);
    setLoading(false);
  };

  const data = useMemo<UseGetMessagesData>(
    () => ({ readMessages, unreadMessages, loading }),
    [readMessages, unreadMessages, loading],
  );

  return data;
};

export const GetMessagesProvider: React.FC<GetMessagesProviderProps> = ({
  children,
  channel,
}) => {
  return <ChannelProvider channelName={channel}>{children}</ChannelProvider>;
};
