"use client";

import { useMemo, useState } from "react";
import { useIsJobSeeker } from "~/hooks/useMockedSession";
import { InputSearch } from "~/scalis-components/core/input";
import { Divider } from "~/scalis-components/core/divider";
import { Icon } from "~/scalis-components/core/icon";
import { Tabs, TabsProps } from "~/scalis-components/team/messages/side-menu/components/tabs";
import {
  MessagesActionType,
  MobileStep,
  useMessages,
  ChatState,
} from "../hooks";
import { ChatEmptyState } from "../chat/empty-state";
import { cn } from "~/utils/cn";
import { PreviewMessages, PreviewMessagesProvider } from "./preview-messages";
import { previewMessagesTitle } from "./side-menu.utils";

export const SideMenu: React.FC = () => {
  const [search, setSearch] = useState<string>("");

  const isJobseeker = useIsJobSeeker();
  const {
    dispatch,
    chatState: prevChatState,
    hasMessages,
    mobileStep,
  } = useMessages();
  const showEmptyState = mobileStep == MobileStep.chatsPreview && !hasMessages;
  const hideForMobile = mobileStep !== MobileStep.chatsPreview;

  const tabs = useMemo<TabsProps["tabs"]>(() => {
    const focusedComponent = (
      <PreviewMessagesProvider>
        <PreviewMessages open title={previewMessagesTitle(isJobseeker)} />
      </PreviewMessagesProvider>
    );

    return [
      { id: "focused", title: "Focused", component: focusedComponent },
      { id: "other", title: "Other", component: <></>, disabled: true },
    ];
  }, [isJobseeker]);

  const openNewChat = () => {
    dispatch({
      type: MessagesActionType.setChatState,
      chatState: ChatState.newChat,
    });

    dispatch({
      type: MessagesActionType.setMobileStep,
      step: MobileStep.chat,
    });
  };

  const openOngoingMessages = () => {
    dispatch({
      type: MessagesActionType.setChatState,
      chatState: ChatState.ongoingChat,
    });
  };

  const toggleChatState = () => {
    const handler =
      prevChatState === ChatState.ongoingChat
        ? openNewChat
        : openOngoingMessages;

    handler();
  };

  return (
    <div
      className={cn(
        "flex h-full flex-col flex-nowrap  bg-neutral-00 last:*:flex-1 last:*:overflow-hidden",
        {
          "hidden md:flex": hideForMobile,
        },
      )}
    >
      <div
        className={cn(
          "leading flex h-12",
          "w-full items-center justify-between rounded-ss-lg  px-4",
          "py-3 text-base font-medium text-neutral-primary",
        )}
      >
        <div>Messages</div>

        <button
          type="button"
          data-testid="icon-pen-to-square"
          className="text-neutral-primary"
          onClick={toggleChatState}
        >
          <Icon icon="fa-regular fa-pen-to-square" />
        </button>
      </div>

      <Divider />

      <InputSearch
        placeholder="Search Messages"
        search={search}
        onChangeSearch={search => setSearch(search)}
      />

      <Divider />

      <div className="min-h-0 flex-initial basis-[fit-content] md:flex-1">
        <Tabs tabs={tabs} />
      </div>

      {showEmptyState && (
        <div className="flex-1 md:hidden">
          <ChatEmptyState />
        </div>
      )}
    </div>
  );
};
