"use client";

import {
  ChatState,
  GetMessagesProvider,
  MobileStep,
  useMessages,
} from "../hooks";
import { ChatEditor } from "./editor";
import { ChatHeader } from "./header";
import { ChatMessages } from "./messages";
import { ChatSearch } from "./search";
import { ChatEmptyState } from "./empty-state";
import { cn } from "~/utils/cn";

export const Chat: React.FC = () => {
  const { currentUser, chatState, mobileStep } = useMessages();
  const hideForMobile = mobileStep === MobileStep.chatsPreview;

  const showEmptyState = chatState === ChatState.ongoingChat && !currentUser;

  return showEmptyState ? (
    <div className="hidden h-full w-full md:block">
      <ChatEmptyState />
    </div>
  ) : (
    <div
      data-testid="messages-chat"
      className={cn("flex h-full w-full flex-col", {
        "hidden md:flex": hideForMobile,
      })}
    >
      <ChatHeader />
      <GetMessagesProvider channel={currentUser?.channel ?? ""}>
        {chatState === ChatState.newChat ? <ChatSearch /> : <ChatMessages />}
        <ChatEditor />
      </GetMessagesProvider>
    </div>
  );
};
