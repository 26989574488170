import { useIsJobSeeker } from "~/hooks/useMockedSession";
import { EmptyState } from "~/scalis-components/core/empty-state";

export const ChatEmptyState: React.FC = () => {
  const isJobSeeker = useIsJobSeeker();

  const title = isJobSeeker
    ? "Still no messages?"
    : "No Messages Currently Found";
  const message = isJobSeeker
    ? "Start applying to jobs to begin getting contacted by employers and recruiters about your applications"
    : "Start reaching out to candidates to start receiving messages";

  return (
    <div className="h-full w-full bg-neutral-00 px-8 md:px-[72px] md:py-[42px]">
      <EmptyState icon="fa-message-question" title={title} message={message} />
    </div>
  );
};
