import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const classNames = tv({
  slots: {
    container: cn(
      "h-[92px] min-w-[280px] w-full",
      "flex flex-nowrap items-start gap-3 py-2 pl-3 pr-6",
      "border-b border-solid border-bg-neutral-30 bg-neutral-00",
    ),
    message:
      "grid h-full w-full grid-cols-2 grid-rows-[auto_1fr] gap-x-2 gap-y-1",
    messageTitle: cn(
      "justify-self-start overflow-hidden text-ellipsis whitespace-nowrap",
      "col-start-1 col-end-2 w-full",
      "line-clamp-1 text-start text-sm font-normal leading-6 text-neutral-primary",
    ),
    messageMetadata: "flex flex-nowrap items-center gap-x-2 justify-self-end",
    messageDate:
      "text-right font-normal leading-4 text-xs tracking-[-0.3px] text-neutral-tertiary",
    messageText: cn(
      "col-start-1 col-end-3 line-clamp-2",
      "h-fit text-ellipsis text-start",
      "text-sm font-normal leading-6 text-neutral-secondary",
    ),
  },
  variants: {
    hasUnreadedMessages: {
      true: {
        container: "bg-surface-brand-rest",
        messageTitle: "font-medium text-brand-primary-rest",
      },
    },
    selected: {
      true: {
        container:
          "cursor-default border-l-4 border-l-bg-brand-primary-dark-00",
      },
    },
  },
});
