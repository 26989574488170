import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { ChatSearchContactCardProps } from "./contact-card.types";
import { cn } from "~/utils/cn";

export const ChatSearchContactCard: React.FC<ChatSearchContactCardProps> = ({
  imgSrc,
  jobTitle,
  name,
  onClick,
  selected,
}) => {
  return (
    <button
      className={cn(
        "flex w-full flex-nowrap items-start gap-3 px-3 py-2 outline-none",
        { "bg-neutral-20": selected },
      )}
      onClick={onClick}
    >
      <ProfilePicture src={imgSrc} />
      <div className={cn("flex flex-1 flex-col flex-nowrap items-start")}>
        <h3 className="text-sm font-medium tracking-[-0.6px] text-neutral-primary">
          {name}
        </h3>

        <p className="text-sm tracking-[-0.6px] text-neutral-secondary">
          {jobTitle}
        </p>
      </div>
    </button>
  );
};
