"use client";

import { Message } from "ably";
import { useEffect, useState } from "react";

import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { Badge } from "~/scalis-components/core/badge";
import {
  ChatState,
  MessagesActionType,
  MobileStep,
  useMessageChannel,
  useMessages,
} from "../../../hooks";
import { ChatUser } from "../../../user";
import useBreakpoint from "~/hooks/useBreakpoint";
import { classNames } from "./preview-message-card-style";
import { PreviewMessageCardProps } from "./preview-message-card.types";
import { getFormattedDate } from "./preview-message-card.utils";
import { replaceStylesInHTMLString } from "../../../messages.utils";

export const PreviewMessageCard: React.FC<PreviewMessageCardProps> = ({
  user,
  isSelected,
}) => {
  const [lastMessage, setLastMessage] = useState<Message>();
  const { currentUser, dispatch } = useMessages() || {};
  const { md } = useBreakpoint();

  const selected = currentUser ? currentUser?.id === user.id : isSelected;

  const { attached } = useMessageChannel(user.channel, message => {
    setLastMessage(message);
  });

  useEffect(() => {
    const isNotMobile = md;
    const setUserOnAttach =
      isSelected && !currentUser && isNotMobile && attached;

    if (setUserOnAttach) {
      setCurrentUser(user);
    }
  }, [attached, md]);

  const setCurrentUser = (user: ChatUser) => {
    dispatch({ type: MessagesActionType.setCurrentUser, currentUser: user });
    dispatch({
      type: MessagesActionType.setMobileStep,
      step: MobileStep.chat,
    });
    dispatch({
      type: MessagesActionType.setChatState,
      chatState: ChatState.ongoingChat,
    });
  };

  const {
    container,
    message,
    messageTitle,
    messageMetadata,
    messageDate,
    messageText,
  } = classNames({
    hasUnreadedMessages: lastMessage?.data.unreadCount,
    selected,
  });

  return (
    <button
      className={container()}
      role="tab"
      aria-label="Select chat user"
      onClick={() => setCurrentUser(user)}
    >
      <ProfilePicture src={user.imgSrc} />
      <div className={message()}>
        <h2 aria-label="User name" className={messageTitle()}>
          {user.name}
        </h2>

        <div className={messageMetadata()}>
          {!!!!lastMessage?.data.unreadCount && (
            <Badge
              value={!!lastMessage?.data?.unreadCount as unknown as number}
            />
          )}

          <div
            aria-label="Last message date"
            role="section"
            className={messageDate()}
          >
            {getFormattedDate(lastMessage?.timestamp)}
          </div>
        </div>

        <p
          aria-label="Message preview"
          className={messageText()}
          dangerouslySetInnerHTML={{
            __html: replaceStylesInHTMLString(lastMessage?.data ?? ""),
          }}
        />
      </div>
    </button>
  );
};
