"use client";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ReactQueryHook } from "~/hooks/hooks.types";
import { GetChannelSuccess } from "~/pages/api/messaging/channels";

export const getChannels = async () => {
  const response = await axios.get<GetChannelSuccess>(
    "/api/messaging/channels",
  );

  return response.data;
};

type UseGetChannelsParams = [];
type UseGetChannelsQueryFn = typeof getChannels;

export const useGetChannels: ReactQueryHook<
  UseGetChannelsParams,
  UseGetChannelsQueryFn
> = () => {
  return useQuery({
    queryKey: useGetChannels.generateKey(),
    queryFn: getChannels,
  });
};

useGetChannels.generateKey = () => ["messaging/channels"];
