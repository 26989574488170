"use client";
import { ChannelProvider } from "ably/react";
import { useEffect } from "react";

import { PreviewMessagesProvider, usePreviewMessages } from "./hooks";
import { PreviewMessagesProps } from "./preview-messages.types";
import { PreviewMessageCard } from "./preview-message-card";
import { MessagesActionType, useMessages } from "../../hooks";
import { Accordion } from "../../chat/components/accordion";
import Spinner from "~/components/spinner";

const PreviewMessagesComponent: React.FC<PreviewMessagesProps> = ({
  open,
  title,
}) => {
  const { chatSessions, isLoading } = usePreviewMessages();
  const { dispatch } = useMessages();

  useEffect(() => {
    if (chatSessions.length > 0) {
      dispatch({ type: MessagesActionType.hasMessages, hasMessages: true });
    }
  }, [chatSessions.length]);

  return (
    <Accordion title={title} open={open && !!chatSessions.length}>
      {isLoading ? (
        <div className="flex h-72 items-center justify-center">
          <Spinner />
        </div>
      ) : (
        chatSessions.map((chatSession, index) => (
          <ChannelProvider
            key={chatSession?.user.channel}
            channelName={chatSession?.user?.channel}
            options={{ params: { rewind: "1" } }}
          >
            <PreviewMessageCard isSelected={index === 0} {...chatSession} />
          </ChannelProvider>
        ))
      )}
    </Accordion>
  );
};

export const PreviewMessages: React.FC<PreviewMessagesProps> = props => (
  <PreviewMessagesProvider>
    <PreviewMessagesComponent {...props} />
  </PreviewMessagesProvider>
);
