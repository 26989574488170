"use client";

import React, { useEffect, useRef } from "react";
import { LoadingState } from "~/components/v2/list-status-placeholder/components";
import { Divider } from "~/scalis-components/core/divider";
import { useGetMessages, useMessages } from "../../hooks";
import { ChatMessageCard } from "./chat-message-card";
import {
  formatMessageDateFromInputFormat,
  replaceStylesInHTMLString,
} from "../../messages.utils";
import { fromUnixTime, isSameDay } from "date-fns";

export const ChatMessages: React.FC = () => {
  const listRef = useRef<HTMLUListElement>(null);
  const { currentUser, users } = useMessages();
  const { readMessages, unreadMessages, loading } = useGetMessages(
    currentUser?.channel,
  );

  const hasNewMessages = unreadMessages?.length > 0;
  const isLoading = !currentUser?.channel || loading;

  const dateDiviter = (date?: number) => {
    return (
      <li className="flex flex-nowrap items-center gap-4">
        <Divider>
          <div className="px-3 py-1 text-center text-xs uppercase tracking-[-0.3px] text-neutral-secondary">
            {formatMessageDateFromInputFormat(Number(date), true)}
          </div>
        </Divider>
      </li>
    );
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({ top: listRef.current.scrollHeight });
    }
  }, [readMessages]);

  const compareDays = (timestamp: number, prevTimestamp: number) => {
    const isSame = isSameDay(
      fromUnixTime(Math.floor(timestamp / 1000)),
      fromUnixTime(Math.floor(prevTimestamp / 1000)!),
    );
    return isSame;
  };

  return isLoading ? (
    <div className="flex h-full w-full flex-col flex-nowrap items-center gap-4 p-3">
      <LoadingState />
    </div>
  ) : (
    <ul
      ref={listRef}
      className="flex h-full min-h-0 w-full flex-col flex-nowrap items-stretch gap-4 overflow-auto bg-neutral-00 px-4 py-4 pb-10"
    >
      {readMessages?.map((message, index) => {
        const isDifferentDate =
          index === 0 ||
          !compareDays(message.createdAt!, readMessages[index - 1].createdAt!);

        return (
          <React.Fragment key={message.id}>
            {(isDifferentDate || hasNewMessages) &&
              dateDiviter(message.createdAt)}
            <li>
              <ChatMessageCard
                timestamp={message.createdAt}
                message={message.message}
                user={users[message.userId]}
              />
            </li>
          </React.Fragment>
        );
      })}
      {unreadMessages.map(message => (
        <li key={message.id}>
          <ChatMessageCard
            timestamp={message.createdAt}
            message={replaceStylesInHTMLString(message.message)}
            user={users[message.userId]}
          />
        </li>
      ))}
    </ul>
  );
};
