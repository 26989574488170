import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { UseNewChatState } from "./use-new-chat-state.types";

const context = createContext<UseNewChatState | null>(null);

export const useNewChatState = (): UseNewChatState => {
  const state = useContext(context);

  if (state === null) {
    throw new Error("useNewState must be used within a NewChatStateProvider");
  }

  return state;
};

export const NewChatStateProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [user, setUser] = useState<UseNewChatState["user"] | null>(null);

  const contextValue = useMemo(() => ({ user, setUser }), [user]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};
