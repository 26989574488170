import Fuse from "fuse.js";

/**
 * Fuzzy search for a list of objects
 *
 * @param list - list of objects to search
 * @param keys - keys to search for, can be nested i.e ['company.name']
 * @param query - search query
 * @returns - list of objects that match the search query
 * @example
 * const keys = ['email', 'full_name', 'business_name']
 * const result = fuzzySearch(list, keys, 'john doe')
 */
export const fuzzySearch = <T extends Record<string, any>, K extends keyof T>(
  list: T[],
  keys: any[], //TODO: implement correct type here (nested keys throws typescript errors when using K[])
  query: string,
) => {
  const options = {
    keys: keys as string[],
    includeScore: true,
    minMatchCharLength: 1,
    threshold: 0.3,
    includeMatches: true,
  };

  const fuse = new Fuse(list, options);

  return fuse.search(query);
};
