import {
  IsJobSeeker,
  JobSeekerUser,
  fetchLoggedUser,
} from "~/queries/user/fetchLoogedUser";
import { useQuery } from "@tanstack/react-query";
import { useGetAvatar } from "./useGetAvatar";
import { AvatarType } from "~/queries/fetchAvatar";

export const useUser = () => {
  const { data: user } = useQuery({
    queryKey: useUser.generateKey(),
    queryFn: fetchLoggedUser,
  });

  const avatarType =
    user?.role === "JOB_SEEKER" ? AvatarType.jobSeeker : AvatarType.companyUser;
  const { data: profilePicture } = useGetAvatar(user?.id, avatarType);

  return {
    user,
    profilePicture,
  };
};

useUser.generateKey = () => ["me"];

export const isJobSeekerUser: IsJobSeeker = (user): user is JobSeekerUser => {
  return user.role === "JOB_SEEKER";
};
