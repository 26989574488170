"use client";

import { Editor } from "~/components/v2/Editor";
import { ChatEditorProps } from "./chat-editor.styles";
import { useEffect, useState } from "react";
import { Button, ButtonSize } from "~/scalis-components/core/button";
import {
  ChatState,
  useMessages,
  MessagesActionType,
  useMessageChannel,
  MobileStep,
} from "../../hooks";
import { useNewChatState } from "../hooks/use-new-chat-state";
import { useStartChat } from "../search/hooks/use-start-chat";
import { errorHandler } from "~/utils/error";
import { useToast } from "~/hooks/useToast";

export const ChatEditor: React.FC<ChatEditorProps> = () => {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const { currentUser, chatState, dispatch } = useMessages();
  const { user: newUser, setUser: setNewUser } = useNewChatState();
  const { publish } = useMessageChannel(currentUser?.channel);
  const { mutate } = useStartChat();
  const { toast } = useToast();

  const newChatDisabled = chatState === ChatState.newChat && !newUser;
  const currentChatDisabled =
    chatState === ChatState.ongoingChat && !currentUser;
  const disabledSendMessage =
    newChatDisabled || currentChatDisabled || !message;

  useEffect(() => {
    if (currentUser?.ongoingMessage) {
      publish("text", currentUser.ongoingMessage);
    }
  }, [currentUser]);

  const sendMessage = async () => {
    setSending(true);

    try {
      publish("text", message);
      setMessage("");
    } catch (error) {
      errorHandler(toast, error);
    } finally {
      setSending(false);
    }
  };

  const senNewMessage = () => {
    if (newUser) {
      setSending(true);
      mutate(
        { recipient: newUser.email },
        {
          onSuccess: data => {
            const currentUser = {
              ...newUser,
              ongoingMessage: message,
              channel: data.channelName,
            };
            dispatch({
              type: MessagesActionType.setCurrentUser,
              currentUser,
            });
            setMessage("");
            setNewUser(null);
            dispatch({
              type: MessagesActionType.setChatState,
              chatState: ChatState.ongoingChat,
            });
            dispatch({
              type: MessagesActionType.setMobileStep,
              step: MobileStep.chat,
            });
          },
          onError: error => {
            errorHandler(toast, error);
          },
          onSettled: () => {
            setSending(false);
          },
        },
      );
    }
  };

  const handleClickSend = () => {
    switch (chatState) {
      case ChatState.newChat:
        senNewMessage();
        break;

      default:
        sendMessage();
    }
  };

  return (
    <div className="relative">
      <Editor
        setValue={setMessage}
        className="h-40 w-full overflow-auto rounded-none border-x-0 bg-neutral-00 p-0 text-sm font-normal"
        contentClassName="w-full h-full overflow-auto p-4"
        placeholderClassName="top-0 left-0 py-3 px-2"
        placeholder="Write a message..."
        autoFocus
        hidePlaceHolderOnFocus
        toolbarDivider
        disabled={newChatDisabled || currentChatDisabled}
        value={message}
      />

      <div className="absolute bottom-4 right-4">
        <Button
          onClick={handleClickSend}
          text="send"
          disabled={disabledSendMessage}
          size={ButtonSize.small}
          loading={sending}
        />
      </div>
    </div>
  );
};
