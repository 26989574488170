"use client";

import { Fragment, useMemo, useState } from "react";
import { Tag } from "~/scalis-components/core/tag";
import { Divider } from "~/scalis-components/core/divider";
import { InputSearch } from "~/scalis-components/core/input";
import { fuzzySearch } from "~/utils/fuzzy";
import { useMessagingContacts } from "./hooks/use-messaging-contacts";
import { useIsJobSeeker } from "~/hooks/useMockedSession";
import { filterDuplicated } from "./chat-search.utils";
import { ChatSearchContactCard } from "./contact-card";
import { useNewChatState } from "../hooks/use-new-chat-state";

export const ChatSearch: React.FC = () => {
  const [search, setSearch] = useState("");
  const [filteredContacts, setFilteredContacts] = useState<typeof contacts>([]);
  const [selectedContact, setSelectedContact] =
    useState<(typeof contacts)[0]>();

  const isJobSeeker = useIsJobSeeker();
  const { data: { employers = [], jobSeekers = [] } = {} } =
    useMessagingContacts();
  const { setUser } = useNewChatState();

  const contacts = useMemo(() => {
    return filterDuplicated(isJobSeeker ? employers : jobSeekers);
  }, [isJobSeeker, employers, jobSeekers]);

  const hanleChangeSearch = (search: string) => {
    const searchResults = fuzzySearch(
      contacts,
      ["email", "name", "title"],
      search,
    );

    setFilteredContacts(searchResults.map(result => result.item));
    setSearch(search);
  };

  const selectContact = (contact: (typeof contacts)[0]) => {
    setSelectedContact(contact);
    setUser({
      name: contact.name,
      email: contact.email,
      imgSrc: contact.profile_image,
      id: contact.id.toString(),
    });
    setSearch("");
    setFilteredContacts([]);
  };

  const clearSearch = () => {
    setUser(null);
    setSelectedContact(undefined);
    setFilteredContacts([]);
  };

  return (
    <div className="flex h-full min-h-0 w-full flex-col flex-nowrap bg-neutral-00 first:*:flex-initial">
      <div className="flex flex-nowrap items-center last:*:flex-1">
        {selectedContact && (
          <Tag
            text={selectedContact.name}
            closeButton={{
              hasCloseButton: true,
              onClose: clearSearch,
            }}
            small
          />
        )}

        <InputSearch
          hideSearchIcon
          placeholder={selectedContact ? "" : "Search people typing a name"}
          onChangeSearch={hanleChangeSearch}
          search={search}
        />
      </div>

      <Divider />

      {!!contacts && (
        <ul className="flex-1 overflow-auto">
          {filteredContacts.map(contact => (
            <Fragment key={contact.id}>
              <li>
                <ChatSearchContactCard
                  name={contact.name}
                  jobTitle={contact.title ?? ""}
                  imgSrc={contact.profile_image}
                  onClick={() => selectContact(contact)}
                  selected={selectedContact?.id === contact.id}
                />
              </li>
              <Divider />
            </Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};
