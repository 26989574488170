"use client";

import React, { useState } from "react";
import { TabsProps } from "./tabs.types";
import { tabClassName } from "./tabs.styles";

export const Tabs: React.FC<TabsProps> = ({ tabs, initialTabId }) => {
  const [tabSelectedId, setTabSelectedId] = useState<string | undefined>(() => {
    const _initialTabId = tabs.find(tab => tab.id === initialTabId)?.id;
    const firstTabId = tabs.find(() => true)?.id;

    return _initialTabId ?? firstTabId;
  });
  const tabContent = tabs.find(tab => tabSelectedId === tab.id)?.component;

  const goToTab = (id: string) => {
    setTabSelectedId(id);
  };

  return (
    <div className="flex h-full min-h-0 flex-col flex-nowrap overflow-hidden bg-neutral-00">
      <div className="flex flex-nowrap">
        {tabs.map(tab => (
          <button
            role="tab"
            key={tab.id}
            className={tabClassName({
              disabled: tab.disabled,
              selected: tab.id === tabSelectedId,
            })}
            onClick={() => goToTab(tab.id)}
            disabled={tab.disabled}
          >
            <span className="text-sm">{tab.title}</span>
          </button>
        ))}
      </div>

      <div className="flex-1 overflow-auto">{tabContent}</div>
    </div>
  );
};
