"use client";

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import {
  ChatSession,
  PreviewMessagesContext,
} from "./use-preview-messages.types";
import { useGetChannels } from "./use-get-channels";

const previewMessagesContext = createContext<PreviewMessagesContext>(
  {} as PreviewMessagesContext,
);

export const usePreviewMessages = (): PreviewMessagesContext => {
  const { chatSessions, isLoading } = useContext(previewMessagesContext);

  return { chatSessions, isLoading };
};

export const PreviewMessagesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data = [], isLoading } = useGetChannels();

  const chatSessions = useMemo<ChatSession[]>(() => {
    return data?.map(channel => ({
      user: {
        id: channel?.recipient?.id?.toString(),
        name: channel?.recipient?.full_name,
        imgSrc: channel?.recipient?.profilePicture,
        channel: channel?.channelName,
      },
    }));
  }, [data]);

  const contextValue = useMemo<PreviewMessagesContext>(
    () => ({
      chatSessions,
      isLoading,
    }),
    [chatSessions, isLoading],
  );

  return (
    <previewMessagesContext.Provider value={contextValue}>
      {children}
    </previewMessagesContext.Provider>
  );
};
