interface FilterParam {
  id: number;
}

export const filterDuplicated = <T extends FilterParam>(toFilter: T[]): T[] => {
  const filtered = new Set<number>();
  const result: T[] = [];

  toFilter.forEach(item => {
    if (!filtered.has(item.id)) {
      filtered.add(item.id);
      result.push(item);
    }
  });

  return result;
};
