"use client";

import { ChannelEvent } from "ably";
import { useChannel } from "ably/react";
import {
  InboundChannelMessage,
  ChannelMessageTypes,
  ChannelPublishName,
  MessageListener,
} from "./use-message-channel.types";
import { useCallback } from "react";

const HISTORY_LIMIT = 100;

export const useMessageChannel = (
  channelName: string = "",
  messageListener?: MessageListener<"text">,
) => {
  const { channel } = !messageListener
    ? useChannel(channelName)
    : useChannel(channelName, messageListener);

  const history = useCallback(async (): Promise<
    InboundChannelMessage<"text">[]
  > => {
    const history = await channel.history({ limit: HISTORY_LIMIT });

    return history.items.reverse();
  }, [channel]);

  const on = useCallback(
    (event: ChannelEvent, callback: VoidFunction) => {
      channel.on(event, callback);
    },
    [channel],
  );

  const publish = useCallback(
    <T extends ChannelPublishName>(
      name: ChannelPublishName,
      message: ChannelMessageTypes[T] | ChannelMessageTypes[T]["data"],
    ) => {
      channel.publish(name, message);
    },
    [channel],
  );

  return {
    history,
    on,
    publish,
    attached: channel.state === "attached",
  };
};
