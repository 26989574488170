import { Icon } from "@scalis/components/core/icon";
import {
  ChatState,
  MessagesActionType,
  MobileStep,
  useMessages,
} from "../../hooks";
import { cn } from "~/utils/cn";
import { iconButtonClassName } from "~/scalis-components/core/icon/icon.styles";

export const ChatHeader: React.FC = () => {
  const { currentUser, chatState, dispatch } = useMessages();

  const title =
    chatState === ChatState.ongoingChat ? currentUser?.name : "New message to";

  const openChatActions = () => {};
  const toggleChatFavoriteStatus = () => {};

  const mobileStepBack = () => {
    dispatch({
      type: MessagesActionType.setMobileStep,
      step: MobileStep.chatsPreview,
    });

    dispatch({
      type: MessagesActionType.setChatState,
      chatState: ChatState.ongoingChat,
    });
  };

  return (
    <header className="flex w-full items-center gap-4 border-b  border-neutral-rest bg-neutral-00 px-4 py-3">
      <h1 className="flex flex-1 flex-nowrap items-center text-start text-base font-medium tracking-[-0.6px] text-neutral-primary">
        <button
          type="button"
          data-testid="icon-chevron-left"
          className={cn(iconButtonClassName, "md:hidden", {
            hidden: chatState === ChatState.newChat,
          })} 
          onClick={mobileStepBack} 
        >
          <Icon icon="fa-solid fa-chevron-left" />
        </button>
        {title}
      </h1>

      <div className="flex flex-[0_0_auto] flex-nowrap">
        <button
          type="button"
          data-testid="icon-dots"
          onClick={openChatActions}
          className={cn(iconButtonClassName, { hidden: chatState === ChatState.newChat })}
        >
          <Icon icon="fa-solid fa-ellipsis" />
        </button>

        <button
          type="button"
          data-testid="icon-star"
          onClick={toggleChatFavoriteStatus}
          className={cn(iconButtonClassName, { hidden: chatState === ChatState.newChat })}
        >
          <Icon icon="fa-regular fa-star" />
        </button>
        <button
          type="button"
          data-testid="icon-x"
          onClick={mobileStepBack}
          className={cn(iconButtonClassName, "md:hidden", {
            hidden: chatState === ChatState.ongoingChat,
          })}
        >
          <Icon icon="fa-solid fa-x" />
        </button>
        
      </div>
    </header>
  );
};
