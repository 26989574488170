"use client";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ReactQueryHook } from "~/hooks/hooks.types";
import { GetContactsSuccess } from "~/pages/api/messaging/contacts";

const fetchContacts = async () => {
  const response = await axios.get<GetContactsSuccess>(
    "/api/messaging/contacts",
  );

  return response.data;
};

type UseMessagingContactsParams = [];
type UseMessagingContactsQueryFn = typeof fetchContacts;

export const useMessagingContacts: ReactQueryHook<
  UseMessagingContactsParams,
  UseMessagingContactsQueryFn
> = () => {
  return useQuery({
    queryKey: useMessagingContacts.generateKey(),
    queryFn: fetchContacts,
  });
};

useMessagingContacts.generateKey = () => ["messaging/contacts"];
