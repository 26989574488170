import { Dispatch, ReducerAction } from "react";
import { ChatUser, User } from "../user";

export enum ChatState {
  newChat = "new-chat",
  ongoingChat = "ongoing-chat",
}

export interface CurrentUser extends ChatUser {
  ongoingMessage?: string;
}

interface MessagesStateUsers {
  [id: string]: User;
}

export enum MobileStep {
  chatsPreview = "chats-preview",
  chat = "chat",
}

export interface MessagesState {
  currentUser?: CurrentUser;
  users: MessagesStateUsers;
  chatState: ChatState;
  mobileStep: MobileStep;
  hasMessages: boolean;
}

export enum MessagesActionType {
  setCurrentUser = "set-current-user",
  setChatState = "set-chat-state",
  setLoggedUser = "set-logged-user",
  setMobileStep = "set-mobile-step",
  hasMessages = "has-messages",
}

interface SetCurrentUserAction extends ReactReducerAction {
  type: MessagesActionType.setCurrentUser;
  currentUser?: CurrentUser;
}

interface SetChatStateAction extends ReactReducerAction {
  type: MessagesActionType.setChatState;
  chatState: ChatState;
}

interface SetLoggedUserAction extends ReactReducerAction {
  type: MessagesActionType.setLoggedUser;
  user: User;
}

interface SetMobileStateAction extends ReactReducerAction {
  type: MessagesActionType.setMobileStep;
  step: MobileStep;
}

interface HasMessagesAction extends ReactReducerAction {
  type: MessagesActionType.hasMessages;
  hasMessages: boolean;
}

type MessagesAction =
  | SetCurrentUserAction
  | SetChatStateAction
  | SetLoggedUserAction
  | SetMobileStateAction
  | HasMessagesAction;

export interface MessagesReducer {
  (state: MessagesState, action: MessagesAction): MessagesState;
}

export interface MessagesUsersReducer {
  (users: MessagesState["users"], user?: User): MessagesState["users"];
}

export interface MessagesContext extends MessagesState {
  dispatch: Dispatch<ReducerAction<MessagesReducer>>;
}
